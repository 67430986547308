
import { defineComponent } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import SoQuotesService from "@/services/SOQuotesService";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Calendar from "primevue/calendar";
import Chips from "primevue/chips";
import Textarea from "primevue/textarea";
import ConfirmDialog from "primevue/confirmdialog";
import Dialog from "primevue/dialog";
import { mapActions, mapGetters, mapMutations } from "vuex";
import SoQuoteDialog from "@/components/Sales/SoQuoteDialog.vue";
import { FilterMatchMode } from "primevue/api";
import SoQuote from "@/types/soquote";
import _debounce from "lodash/debounce";
import _uniq from "lodash/uniq";
import MultiselectWithButton from "../UI/MultiselectWithButton.vue";
import CustomerService from "@/services/CustomerService";
import KanbanView from "./KanbanView.vue";
import Utils from "@/utility/utils";

export default defineComponent({
  components: {
    LoadingSpinner,
    Chips,
    Textarea,
    ConfirmDialog,
    Dialog,
    InputText,
    Button,
    DataTable,
    Column,
    Calendar,
    SoQuoteDialog,
    KanbanView,
    MultiselectWithButton,
  },
  created() {
    if (this.customerIndex !== undefined) {
      this.filters.name.value = [];
      this.selectedFilterCusts = [this.getCurrentCustomer];
      this.storeSortFilters();
    } else {
      this.filters.name.value = [];
      this.selectedFilterCusts = [];
    }
    this.selectedFilterQuotedBy = [];
    this.applyStoredSortFilters();
    this.fetchQuotesData(false);
  },
  mounted() {
    this.first = this.getFirstRow;
  },
  unmounted() {
    this.clearAndResetQuotes();
  },
  computed: {
    ...mapGetters({
      getSOQuotes: "soQuotes/getSoQuotes",
      getQuotes: "soQuotes/getQuote",
      getRangeEnd: "soQuotes/getRangeEnd",
      getFirstRow: "soQuotes/getFirstRow",
      getUserId: "session/getUserId",
      getUsers: "users/getUsers",
      getLoadingQuotes: "soQuotes/getLoadingQuotes",
      getMailControls: "control/getMailControls",
      getCurrentCustomer: "customerInquiry/getCurrentCustomer",
      getCurrentCustTabIndex: "customerInquiry/getCurrentCustTabIndex",
      getSalesQuotesIds: "customerInquiry/getSalesQuotesIds",
      getSalesQuotesSortFilters: "filters/getSalesQuotesSortFilters",
      filterInactiveCustomers: "mrkControl/filterInactiveCustomers",
    }),
  },
  props: {
    customerIndex: Number,
    currentView: String,
    maxExportRows: {
      type: Number,
      required: true,
    },
    printExportData: {
      type: Boolean,
      required: false,
    },
    isResultView: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update-badge", "lastUpdated"],
  data() {
    return {
      printExportItems: [] as Array<SoQuote>,
      selectedSoId: "",
      dateStart: "",
      dateEnd: "",
      selectedDateStart: "",
      selectedDateEnd: "",
      isLoadingSoQuotes: false,
      showSoQuoteDialog: false,
      isEditing: false,
      soQuotes: [],
      soQuote: null as unknown,
      oldSoQuote: null as unknown,
      first: 0,
      rows: 10,
      singleOption: false,
      id: "",
      sortOrder: 1,
      newQuote: false,
      sortField: "",
      page: 1,
      quotesView: [] as Array<SoQuote>,
      loadingPDFQuotes: [],
      formatedLists: [] as Array<any>,
      status: [
        { status: "New", initial: "N" || "NEW" || "New" },
        { status: "Back Order", initial: "B" },
        { status: "Closed", initial: "C" || "c" },
        { status: "Active", initial: "" },
      ],
      statusLabel: {
        N: "New",
        B: "Back Order",
        C: "Closed",
      },
      quotesService: new SoQuotesService(process.env.VUE_APP_ABSTRACTION_API),
      modalFile: "",
      html: false,
      secure: false,
      from: [""],
      to: null,
      cc: null,
      bcc: null,
      subject: null,
      body: null,
      deleteDialog: false,
      showAddModal: false,
      showEmailModal: false,
      modalAttachment: "",
      submitted: false,
      filters: {
        id: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
        name: {
          value: [] as any,
          matchMode: FilterMatchMode.IN,
        },
        quoted_by: {
          value: [] as any[],
          matchMode: FilterMatchMode.CONTAINS,
        },
        status: {
          value: [] as any[],
          matchMode: FilterMatchMode.CONTAINS,
        },
        date: {
          value: [] as Array<Date>,
          matchMode: FilterMatchMode.BETWEEN,
        },
      },
      selectedFilterQuotedBy: [] as any[],
      selectedFilterCusts: [] as any[],
      searchedFilterCusts: [] as any[],
      selectedFilterStatus: [] as any[],
      selectedFilterDate: undefined as any,
      selectedFilterId: "",
      custService: new CustomerService(),
      quotedBy: "",
      selectedCustomer: "",
      totalRecords: 0,
      allExportColumns: [
        { field: "id", header: "ID", input: true },
        { field: "status", header: "Status", input: true },
        { field: "name", header: "Customer", input: true },
        { field: "date", header: "Date", input: true },
        { field: "quoted_by", header: "Quoted By", input: true },
        { field: "quote_amount", header: "Quote Total", input: true },
      ],
      dynamicColumns: [
        { field: "id", header: "ID", input: true },
        { field: "status", header: "Status", input: true },
        { field: "name", header: "Customer", input: true },
        { field: "date", header: "Date", input: true },
        { field: "quoted_by", header: "Quoted By", input: true },
        { field: "quote_amount", header: "Quote Total", input: true },
      ],
      isProduction: this.isProduction,
    };
  },
  watch: {
    getCurrentCustomer() {
      if (
        this.getCurrentCustTabIndex == this.customerIndex &&
        this.getCurrentCustomer
      ) {
        this.filters.name.value = [];
        this.selectedFilterCusts = [this.getCurrentCustomer];
        this.filterCust();
      }
    },
    soQuotes() {
      if (!this.isResultView) {
        this.formatBoardList();
      }
    },
    isResultView() {
      this.clearAllFilters();

      if (!this.isResultView) {
        this.formatBoardList();
      }
      this.fetchQuotesData(false);
    },
  },
  inject: ["isProduction"],
  methods: {
    ...mapActions({
      fetchQuotes: "soQuotes/fetchSoQuotes",
      getQuotePDF: "soQuotes/getQuotePDF",
      setFirstRow: "soQuotes/setFirstRow",
      fetchControl: "control/fetchControl",
      clearAndResetQuotes: "soQuotes/clearAndResetQuotes",
      addOpenedSalesOrder: "salesInquiry/addOpenedSalesInquiry",
      setSalesQuoteSortFilters: "filters/setSalesQuoteSortFilters",
      addNotification: "notification/add",
      clearPrintData: "printableDatatable/clearData",
      setPrintData: "printableDatatable/setData",
      setPrintDefaultColumns: "printableDatatable/setDefaultColumns",
      setPrintAvaialbleColumns: "printableDatatable/setAvaialbleColumns",
    }),
    ...mapMutations({
      clearSoQuotes: "soQuotes/CLEAR_SO_QUOTES",
      resetPage: "soQuotes/RESET_PAGE",
    }),
    hideDialog() {
      this.showEmailModal = false;
      this.modalAttachment = "";
      this.modalFile = "";
      this.html = false;
      this.secure = false;
      this.from = [""];
      this.to = null;
      this.cc = null;
      this.bcc = null;
      this.subject = null;
      this.body = null;
      this.mailControls();
    },
    async email() {
      // this.submitted = true;
      // const isFormCorrect = await this.v$.$validate();
      // if (isFormCorrect) {
      //   this.emailAttachment({
      //     From: this.from,
      //     To: this.to,
      //     Cc: this.cc,
      //     Bcc: this.bcc,
      //     Subject: this.subject,
      //     Body: this.body,
      //     Html: this.html,
      //     Secure: this.secure,
      //     AttachmentId: this.modalAttachment,
      //     Client: this.getClient,
      //   });
      //   this.mailControls();
      //   this.hideDialog();
      // } else {
      //   return;
      // }
    },
    async printTable() {
      //block a print with no criteria applied
      if (this.totalRecords === 0) {
        const notification = {
          message: `No records to print or export.`,
          type: "error",
        };
        this.addNotification(notification);
        return;
      }
      if (this.totalRecords > this.maxExportRows) {
        const notification = {
          message: `Please filter the results before printing.`,
          type: "error",
        };
        this.addNotification(notification);
      } else {
        this.clearPrintData();
        // get full dataset for the current criteria
        await this.fetchQuotesData(false);
        this.setPrintData(this.printExportItems);
        this.setPrintDefaultColumns(this.dynamicColumns);
        this.setPrintAvaialbleColumns(this.allExportColumns);

        window.open("/printable-view?print=1&showSelection=1", "_blank");
      }
    },
    mailControls() {
      if (this.getMailControls) {
        let controls = this.getMailControls;
        this.from[0] = controls.m3services_sender_address;
        this.secure = controls.m3services_secure == "Y" ? true : false;
      }
    },
    emailModal(id: any, file: any) {
      this.submitted = false;
      this.showEmailModal = true;
      this.modalAttachment = id;
      this.modalFile = file;
    },
    async fetchQuotesData(addSoQuote: boolean) {
      let sortBy = "";
      let sortOrderRecordsBy = "";

      // Sort logic
      switch (this.sortField) {
        case "formatted_date":
          sortBy = "date";
          break;
        default:
          sortBy = this.sortField.replaceAll("_", ".");
          break;
      }

      if (this.sortOrder === -1) {
        sortOrderRecordsBy = "DEC";
      }

      // Filter logic
      this.isLoadingSoQuotes = true;
      if (this.currentView === "customers") {
        if (this.selectedFilterId) {
          if (
            !this.getSalesQuotesIds(this.customerIndex).includes(
              this.selectedFilterId,
            )
          ) {
            this.isLoadingSoQuotes = false;
            return;
          }
        }
      }

      this.filters.id.value = this.selectedFilterId;
      this.filters.quoted_by.value = [...this.selectedFilterQuotedBy];
      this.filters.status.value = [...this.selectedFilterStatus];
      if(this.customerIndex !== undefined) {
        this.filters.name.value = [];
      } else {
        this.filters.name.value = this.selectedFilterCusts.map((cust) => {
        return cust.name;
      });
      }
      this.filters.date.value = [];
      if (this.selectedFilterDate) {
        if (this.selectedFilterDate[0] != null)
          this.selectedDateStart = this.selectedFilterDate[0];
        if (this.selectedFilterDate[1] != null)
          this.selectedDateEnd = this.selectedFilterDate[1];

        this.selectedDateStart = this.formatStringDate(this.selectedDateStart);
        this.selectedDateEnd = this.formatStringDate(this.selectedDateEnd);

        if (this.selectedDateStart)
          this.filters.date.value.push(new Date(this.selectedFilterDate[0]));
        if (this.selectedDateEnd)
          this.filters.date.value.push(new Date(this.selectedFilterDate[1]));
      }
      this.storeSortFilters();

      this.fetchQuotes({
        addSoQuote: addSoQuote,
        custs: this.getCustomersFilter(),
        ids: this.filters.id.value,
        quotedBy: this.getQuotedByFilter(),
        dateStart: this.selectedDateStart,
        dateEnd: this.selectedDateEnd || this.selectedDateStart,
        status: this.selectedFilterStatus,
        correls: "quote_amount",
        sortBy: sortBy,
        sortOrder: sortOrderRecordsBy,
        disableSortedSample: "Y"
      })
        .then((resp) => {
          this.$emit("lastUpdated", Date.now());
          if (addSoQuote) {
            this.soQuotes = [...this.soQuotes, ...resp.data] as any;
          } else {
            this.soQuotes = resp.data;
          }

          this.printExportItems = resp.data.map((item: any) => {
            return {
              id: item.id,
              status: Utils.formatStatus(item.status),
              name: item.name,
              date: item.date,
              quoted_by: item.quoted_by,
              quote_amount: item.quote_amount,
            };
          });

          this.totalRecords = this.soQuotes.length;
          this.$emit("update-badge", "soquote_ids", resp.count);
        })
        .finally(() => {
          this.isLoadingSoQuotes = false;
          if (!addSoQuote) {
            this.first = 0;
            this.page = 1; //might be totally unecessary
          }
        });
    },
    download(id: any) {
      this.getQuotePDF({
        recordId: id,
      });
    },
    filterCust() {
      this.isLoadingSoQuotes = true;
      this.filters.name.value = this.selectedFilterCusts.map((cust) => {
        return cust.name;
      });
      this.searchedFilterCusts = [...this.selectedFilterCusts];
      this.fetchQuotesData(false);
    },
    handleCustomerSelectFilter: _debounce(async function (event) {
      // @ts-expect-error becuas of exploit this
      const customers = await this.custService.getAllCustomers({
        selection: event.value,
        // @ts-expect-error becuas of exploit this
        activeOnly: this.filterInactiveCustomers,
      });
      // @ts-expect-error becuas of exploit this
      this.searchedFilterCusts = _uniq([
        // @ts-expect-error becuas of exploit this
        ...customers.cust_items.sort((a, b) => a.name.localeCompare(b.name)),
        // @ts-expect-error becuas of exploit this
        ...this.selectedFilterCusts,
      ]);
    }, 1000),
    getCustomersFilter() {
      return this.selectedFilterCusts.map((cust) => {
        return cust.cust_id;
      });
    },
    downloadIcon(id: any) {
      let downloading = this.getLoadingQuotes?.includes(id) || false;
      let downloaded = this.getQuotes;
      return {
        "pi pi-download":
          downloaded.find((i: any) => i.id === id) === undefined &&
          !downloading,
        "pi pi-spin pi-spinner": downloading,
        "pi pi-file-pdf":
          downloaded.find((i: any) => i.id === id) !== undefined &&
          !downloading,
      };
      // if (!downloading) {
      //   if(downloaded.find((i: { id: string; }) => i.id === this.id) === undefined)
      //     return "pi pi-download";
      //   else
      //     return "pi pi-file-pdf"
      // } else {
      //   return "pi pi-spin pi-spinner";
      // }
    },
    sortData(event: any) {
      this.sortField = event.sortField;
      this.sortOrder = event.sortOrder;

      this.fetchQuotesData(false);
    },
    getQuotedByFilter() {
      let quoted = [];
      if (this.filters.quoted_by.value) {
        quoted = Object.values(this.filters.quoted_by.value);
      }
      return quoted;
    },
    clearAllFilters() {
      if (this.customerIndex !== undefined) {
        this.filters.name.value = [];
        this.selectedFilterCusts = [this.getCurrentCustomer];
      } else {
        this.filters.name.value = [];
        this.selectedFilterCusts = [];
      }
      this.filters.quoted_by.value = [];
      this.selectedFilterQuotedBy = [];
      this.selectedFilterId = "";
      this.filters.id.value = "";
      this.selectedDateStart = "";
      this.selectedDateEnd = "";
      this.selectedFilterDate = null;
      this.filters.date.value = [];
      this.selectedFilterStatus = [];
    },
    clearAllFiltersAndFetch() {
      this.clearAllFilters();
      this.fetchQuotesData(false);
    },
    clearQuoteByToFilter() {
      this.filters.quoted_by.value = [];
      this.selectedFilterQuotedBy = [];
      this.fetchQuotesData(false);
    },
    clearQuoteIdSearch() {
      this.selectedFilterId = "";
      this.filters.id.value = "";
      this.fetchQuotesData(false);
    },
    clearCustomerSearch() {
      this.filters.name.value = [];
      this.selectedFilterCusts = [];
      this.fetchQuotesData(false);
    },
    clearDateFilter() {
      this.selectedDateStart = "";
      this.selectedDateEnd = "";
      this.selectedFilterDate = null;
      this.filters.date.value = [];
      this.fetchQuotesData(false);
    },
    clearStatusSearchBar() {
      this.selectedFilterStatus = [];
      this.fetchQuotesData(false);
    },
    pageClick(event: any) {
      this.setFirstRow(event.first);
      if (
        (event.page == event.pageCount ||
          event.page == event.pageCount - 1 ||
          event.page == event.pageCount - 2) &&
        this.soQuotes.length == this.getRangeEnd - 100
      ) {
        this.fetchQuotesData(true);
      }
    },
    showRow(event: any) {
      this.soQuote = event;
      this.oldSoQuote = JSON.parse(JSON.stringify(event));
      this.id = event.id;
      this.openSoQuote(true);
    },
    rowClick(event: any) {
      let itemToEdit = event;
      if (event.data) {
        itemToEdit = event.data;
      }
      if (this.isProduction) {
        this.showRow(itemToEdit);
      } else {
        itemToEdit["saleType"] = "quotes";
        itemToEdit["oldRecord"] = JSON.parse(JSON.stringify(itemToEdit));
        this.addOpenedSalesOrder({ ...itemToEdit });
        this.$router.push(`/sales/quotes/${itemToEdit.id}`);
      }
    },
    openSoQuote(edit: boolean) {
      this.showSoQuoteDialog = true;
      this.isEditing = edit;
      this.singleOption = false;
      this.newQuote = !edit;
    },
    formatCurrency(amount: string) {
      return Utils.formatPrice(amount);
    },
    formatStringDate(dueDate: string) {
      if (!dueDate || dueDate == null) return "";
      const reformatedDueDate = Utils.formatDate(dueDate);

      return reformatedDueDate;
    },
    handleFilter(event: any) {
      //this.$emit("update-badge", "soquote_ids", event.filteredValue.length);
      return false;
    },
    formatBoardList() {
      let controlStatusList: Array<any> = [];
      controlStatusList = JSON.parse(JSON.stringify(this.status));
      controlStatusList.unshift({
        status: "Unassigned",
      });
      controlStatusList.forEach((element) => (element.items = []));
      this.soQuotes.forEach((item: any) => {
        const statusIndex = controlStatusList.findIndex(
          (element) => element.initial === item.status,
        );
        if (statusIndex >= 0) {
          // if (!controlStatusList[statusIndex].items) {
          //   controlStatusList[statusIndex].items = [];
          // }
          controlStatusList[statusIndex].items.push(item);
        } else {
          controlStatusList[0].items.push(item);
        }
      });
      this.formatedLists = controlStatusList;
    },
    onStatusChange(event: any) {
      let quoteWithNewStatus = JSON.parse(
        JSON.stringify(event.item.added.element),
      );
      quoteWithNewStatus.status = event.status;
      this.quotesService.putSOQuote({
        quoteId: event.item.added.element.id,
        newQuote: quoteWithNewStatus,
        oldQuote: event.item.added.element,
      });
    },
    storeSortFilters() {
      const filterCusts = {
        value: this.selectedFilterCusts,
      };
      const sortFiltersToStore: any = Utils.getSortFiltersToStore({
        filters: { ...this.filters, name: filterCusts },
        sortField: this.sortField,
        sortOrder: this.sortOrder,
      });
      this.setSalesQuoteSortFilters(sortFiltersToStore);
    },
    applyStoredSortFilters() {
      const filters = this.getSalesQuotesSortFilters.filters;
      for (const filter in filters) {
        switch (filter) {
          case "id":
            this.selectedFilterId = filters[filter];
            break;
          case "name":
            this.selectedFilterCusts = filters[filter];
            this.handleCustomerSelectFilter(filters[filter]);
            break;
          case "quoted_by":
            this.selectedFilterQuotedBy = filters[filter];
            break;
          case "status":
            this.selectedFilterStatus = filters[filter];
            break;
          case "formatted_date":
            this.selectedFilterDate = filters[filter];
            break;
        }
      }
      this.sortField = this.getSalesQuotesSortFilters.sort.sortField;
      this.sortOrder = this.getSalesQuotesSortFilters.sort.sortOrder;
    },
  },
});
